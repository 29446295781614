*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
  font-family: 'Lato'; /*a name to be used later*/
  src: url("../src/Font/Lato-Regular.ttf"); /*URL to font*/
}
@font-face {
  font-family: 'LatoLight'; /*a name to be used later*/
  src: url("../src/Font/Lato-Light.ttf"); /*URL to font*/
}

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root{
  --dark: #61605C;
  --lightDark: #878787;
  --white: #FAFAFA;
  --lightWhite: #D7D5D0;
  --green:#60BF01;
  --red: #C70716;

  --Braun:  #8B5A2B ;
  --Beige:  #F5F5DC ;
  --Cremefarben:  #FFFDD0 ;
  --Hellbraun:  #D2B48C ;
  --Dunkelrot: #8B0000;

  --Dunkelgrün: #006400;
  --Dunkelbraun: #8B4513;
  --Hellgrau: #D3D3D3;
  --Grau: #808080;
  --Dunkelgrau: #333333;
  --Cremeweiß: #F5F5F5;
  --Rauchweiß: #FAFAFA;

  --color1: #232426;
  --color2: #565759;
  --color3: #88898C;
  --color4: #d8e6f2bf;
  --color5: #BABDBF;
  --color6: #D8E6F2;

}

a {
  text-decoration: none;
  color: var(--dark);
}
a:hover {
  color: var(--lightDark);
  text-decoration: none;
}

code {
  font-family: 'Lato';
}
