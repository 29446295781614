/* General */
body{
  font-family: 'Lato';
}

/*Top Bar*/
#topbar {
  background: var(--color5);
  padding: 10px;
}
#topbar .topbarItems {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px 0;
}
#topbar .topbarItems i {
  color: rgb(var(--Dunkelgrau) / 30%);
  line-height: 0;
  margin-right: 10px;
  font-size: 20px;
}
#topbar .topbarItems a {
  color: rgb(var(--Dunkelgrau) / 30%);
  padding: 0 20px;
}
#topbar .topbarItems a:hover, .topbarItems i:hover {
  color: var(--Dunkelgrau);
}
#topbar .item{
  padding: 0 10px;
  font-size: 16px;
}

/* Navbar */
#navbar{
  background-color: var(--Rauchweiß);
  color: var(--dark);
  display: flex;
  justify-content: center;
  /* position: -webkit-sticky; */
  position: sticky;
  padding: 16px 0;
  top: 0;
  z-index: 99;
}

.navContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
}

.navImg{
  width: 100px;
}

.navbarMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbarItem {
  margin: 0 10px;
}

.navbarLink {
  text-decoration: none;
  color: var(--Dunkelgrau);
  font-size: 22px;
  padding: 15px;
  transition: all 0.2s ease-in-out;
}

.navbarLink:hover{
  color: var(--Dunkelgrau);
  text-decoration: underline;
}

.navbarMenu:hover .navbarLink:not(:hover){
  color: rgb(var(--Dunkelgrau) / 30%);
  opacity: 0.8;
  filter: blur(0.5px);
}

/*Header Section*/
header{
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.781), rgba(0, 0, 0, 0.649)), url("../src/images/PHOTO-2023-03-16-22-46-36.JPG") center; 
  background-size: cover;
  overflow: hidden;
  position: relative;
  padding: 50px;
}

/* Header container */
.headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;
}

.headerContainer h1{
  text-align: center;
  color: var(--white);
  padding-bottom: 25px;
  font-size: 4em;
}

.headerContainer h2{
  text-align: center;
  color: var(--Rauchweiß);
  padding-bottom: 25px;
  font-size: 1.8em;
}

.headerContainer p{
  text-align: justify;
  color: var(--Cremeweiß);
  padding-bottom: 25px;
  font-size: 1.3em;
  line-height: 1.3;
}

.headerContainer button{
  background-color: transparent;
  color: var(--Rauchweiß);
  border: 3px solid var(--color4);
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;

  appearance: none;
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  line-height: normal;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.headerContainer button:hover {
  color: var(--Dunkelgrau);
  background-color: var(--color4);
  box-shadow: rgba(0, 0, 0, 0.656) 0 8px 15px;
  transform: translateY(-2px);
}

.headerContainer button:active {
  box-shadow: none;
  transform: translateY(0);
}


/* About */
#about{
  background-color: var(--Hellgrau);
}

.aboutContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.aboutTeam, .aboutText{
  flex-direction: column;
  justify-content:space-between;
  align-items: center;
  padding: 25px;
  max-width: 1100px;
}

.aboutTeam img{
  width: 350px;
  filter: drop-shadow(rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px);
  margin: 15px;
}

.aboutTeam h1{
  text-align: center;
  padding: 10px;
}

.aboutTeam h2{
  text-align: center;
  font-size: 1.2em;
  font-style: italic;
}

.aboutText p{
  text-align: justify;
  color: var(--dark);
  padding-bottom: 25px;
  font-size: 1.3em;
  line-height: 1.5;
}

#services{
padding: 50px 0;
}

#services h1{
text-align: center;
padding-bottom: 25px;
font-size: 4em;
text-decoration: underline;
text-decoration-color: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0), rgba(0, 0, 0, 0));
}

.serviceContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
  justify-content: space-evenly; 
  align-content: space-around;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 50px;
  width: 75vw;
  max-width: 600px;
  color: var(--color1);
  background: var(--color5);
  border-radius: 16px;
  height: 450px;
}

.card:hover {
  background: var(--color4);
  color: var(--Dunkelgrau);
}

.cardText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 75vw;
    max-width: 600px;
    padding: 20px 30px 20px 20px;
}

.card img {
  object-fit: cover;
  max-width: 300px;
  width: 28vw; 
  height: 110%;
  /* margin: -50px 0 -50px -50px;  */
  border-radius: inherit;
  box-shadow: 0 20px 10px rgba(0, 0, 0, 0.249);
}

.cardText i{
padding: 10px 0;
font-size: 2.5em;
}

.cardText h2 {
  padding: 10px 0;
  font-size: 1.6em;
  margin-right: 30px;
  color: rgb(inherit  / 90%);
}

.cardText p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(inherit / 70%);
  padding-bottom: 5px;
}

.cardText ul {
  font-size: 18px;
  font-weight: 400;
  color: rgb(inherit / 70%);
  margin-bottom: 24px;
  text-align: start;
}

.cardText button {
  border: 0;
  background: linear-gradient(90deg, var(--Cremefarben), var(--Beige));
  color: black;
  padding: 16px 26px;
  font-size: 18px;
  border-radius: 40px;
}

.cardText button:hover {
  background: var(--Cremeweiß);
  color: black;
}

#imageGallery{
  background-color: var(--color5);
  padding: 50px;
}

#imageGallery h1{
  text-align: center;
  padding-bottom: 25px;
  font-size: 4em;
  text-decoration: underline;
}

.images{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-around;
}

.images img{
  padding: 25px;
  width: 500px;
  height: 600px;
}

/* Contact */
#contact{
  padding: 50px 0;
}

#contact h1{
  text-align: center;
  padding-bottom: 25px;
  font-size: 4em;
  text-decoration: underline;
}

.content{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.contactContainer{
  margin: 0 15px;
  padding: 20px;
  width: 100%;
  max-width: 60vh;
  background-color: var(--Hellgrau);
}

.contactContainer h2{
  font-size: 22px;
}

.contactInformation{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 0;
  font-size: 20px;
}

.info{
  padding: 10px 0
}

.contactInformation i {
  padding-right: 15px;
}

.contactInformation h4 {
  color: var(--Dunkelgrau);
  font-size: 1.2em;
  padding-left: 50px;
}

.contactInformation a {
  margin-left: 50px;
  color: var(--Dunkelgrau);
  font-size: 22px;
  font-weight: 700;
}

/*Footer*/
footer{
  background-color: #BABDBF;
  padding: 40px 0;
}
.footerContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.footerLinks {
  display: flex;
  flex-direction: row;
  justify-content: 	space-between;
  align-items: center;
  color: var(--Dunkelgrau);
  margin: 25px 0;
}
.footerLinks a{
  padding: 0 20px;
  color: var(--color1);
}
.copyright{
  text-align: center;
  margin: 25px 0;
}
footer img{
  height: 200px;
  width: auto;
}
  
#legalNotice{
  padding: 30px;
}

.legalNotice h1{
  text-align: center;
  padding-bottom: 25px;
  font-size: 4em;
  text-decoration: underline;
  text-decoration-color: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0), rgba(0, 0, 0, 0));
}

.legalNotice p{
  padding: 30px 0;
  text-align: justify;
  font-size: 18px;
  color: var(--Dunkelgrau);
}

.legalNotice ul{
  list-style: none;
}

@media only screen and (max-width: 750px)  {

  #imageGallery h1, #services h1, #contact h1, #legalNotice h1{
    text-align: center;
    padding-bottom: 25px;
    font-size: 2em;
    text-decoration: underline;
  }

  #topbar .topbarItems {
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 0;
  }

  #topbar .item{
    padding: 10px 0;
    font-size: 16px;
  }

  /* #navbar{
    display: none;
  } */

  header{
    height: 100vh;
    padding: 20px;
  }

  .headerContainer h1{
    font-size: 2em;
  }
  
  .headerContainer h2{
    font-size: 1.3em;
  }
  
  .headerContainer p{
    font-size: 1em;
    line-height: 1.5;
  }

  .aboutContainer{
    padding: 0 5px ;
  }

  .aboutTeam img{
    width: 250px;
  }

  .aboutText p{
    text-align: justify;
    color: var(--dark);
    padding-bottom: 10px;
    font-size: 1em;
    line-height: 1.5;
  }

  .card {
    margin: 100px 40px;
    /* padding-left: 50px;
    padding-right: 50px; */
    width: 100%;
    text-align: center;
    flex-direction: column;
    height: 100%;
  }

  .card h2 {
    margin-right: 0;
    font-size: 1.6em;
  }

  .card img {
    margin: -20px 0 30px 0;
    width: 100%;
    height: 300px;
  }

  #imageGallery{
    padding: 20px;
  }

  .images{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .images img{
    width: 100%;
    height: auto;
    padding: 15px 0;
  }

  #contact{
    padding: 50px 0;
  }
  
  .contactContainer h2{
    font-size: 20px;
  }
  
  .contactInformation i {
    padding-right: 15px;
  }
  
  .contactInformation h4 {
    font-size: 1.2em;
    padding-left: 0;
    line-height: 1.7;
  }
  
  .contactInformation a {
    margin-left: 0;
    line-height: 1.7;
  }

  .copyright{
    font-size: 12px;
  }

}